<script setup lang="ts">
import ShoppingBagIcon from '~/storefront/assets/icons/shopping-bag.svg'
import BarsIcon from '~/storefront/assets/icons/bars.svg'
import UserIcon from '~/storefront/assets/icons/user.svg'
import CheckIcon from '~/storefront/assets/icons/checkmark.svg'
import WishlistIcon from '~/storefront/assets/icons/heart.svg'
import SearchIcon from '~/storefront/assets/icons/search.svg'
import TimesIcon from '~/storefront/assets/icons/times.svg'

const uiState = useUiState()

const { toggleCartSidebar, toggleWishlistSidebar, toggleMobileMenu } = uiState

const { isMobileOrTablet } = useDevice()
const { useAb25019, useAb25020 } = useAbTest()
const { ab25019ShowV1 } = useAb25019()
const { showV0, showV1 } = useAb25020()
const { sendAbTestEvent } = useAbTestStore()

const { loadTotalQty: loadCartTotalQty, cart } = useCart()
const { loadWishlistIds } = useWishlistStore()
const { wishlistIds } = storeToRefs(useWishlistStore())
const { isLoggedIn } = storeToRefs(useCustomerStore())

const isSearchOpen = ref(false)

const wishlistItemsQty = computed(() => wishlistIds.value?.items_count || 0)

const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0)

const localePath = useLocalePath()

const accountUrl = ref(localePath(`/customer/account/login`))

onMounted(async () => {
  if (isMobileOrTablet) {
    if (ab25019ShowV1.value) {
      sendAbTestEvent(25019, 'show search icon')
    } else {
      sendAbTestEvent(25019, 'show search bar')
    }
  } else {
    sendAbTestEvent(25020, 'show search-bar')
  }
  await loadCartTotalQty()
  if (isLoggedIn.value) {
    await loadWishlistIds()
    accountUrl.value = localePath(`/customer/account`)
  }
})

useCustomerStore().$subscribe(() => {
  if (isLoggedIn.value) {
    loadWishlistIds()
    accountUrl.value = localePath(`/customer/account`)
  }
})

const toggleNavMenu = () => {
  toggleMobileMenu()
  if (ab25019ShowV1.value) {
    sendAbTestEvent(25019, 'show search bar')
  }
}

const openSearch = () => {
  isSearchOpen.value = true
  sendAbTestEvent(25020, 'click search-glass')
}

const clickAccount = () => {
  if (!isMobileOrTablet) sendAbTestEvent(25021, 'click account')
}
const clickWishlist = () => {
  toggleWishlistSidebar()
  if (!isMobileOrTablet) sendAbTestEvent(25021, 'click wishlist')
}
const clickCart = () => {
  toggleCartSidebar()
  if (!isMobileOrTablet) sendAbTestEvent(25021, 'click cart')
}
</script>

<template>
  <div class="app-header grid-container">
    <SfHeader :class="{ 'header-on-top': isSearchOpen }">
      <template #logo>
        <HeaderLogo />
      </template>

      <template #header-icons>
        <SfButton
          class="sf-button--pure sf-header__mobile-menu-toggle mobile-only"
          aria-label="Menu"
          data-testid="toggleMobileMenuIcon"
          @click="toggleNavMenu"
        >
          <BarsIcon />
          <span>menu</span>
        </SfButton>
        <SfButton
          v-if="ab25019ShowV1"
          class="sf-button--pure sf-header__mobile-menu-toggle search mobile-only"
          @click="toggleMobileMenu(true)"
        >
          <SearchIcon :aria-label="$t('Search')" />
        </SfButton>
      </template>

      <template #default>
        {{ null }}
      </template>

      <template #search>
        <SearchBar v-if="showV0 && !ab25019ShowV1" @SearchBar:toggle="isSearchOpen = $event" />
      </template>

      <template #ab25020-search-bar>
        <SearchBar25020SearchBar v-if="isSearchOpen && showV1" @SearchBar:toggle="isSearchOpen = $event" />
      </template>

      <template #aside>
        <div class="sf-header__icons">
          <SfButton
            v-if="showV1 && isSearchOpen === false"
            class="sf-button--pure sf-header__action"
            @click="openSearch"
          >
            <SearchIcon :aria-label="$t('Search')" />
          </SfButton>

          <SfButton
            v-if="showV1 && isSearchOpen === true"
            class="sf-button--pure sf-header__action"
            @click="isSearchOpen = false"
          >
            <TimesIcon :aria-label="$t('Close')" />
          </SfButton>

          <StoreSwitcher v-if="showV0" class="desktop-only sf-header__action--switcher" />

          <SfLink
            class="sf-button sf-button--pure sf-header__action"
            :aria-label="$t('Account')"
            :link="accountUrl"
            data-testid="accountIcon"
            @click="clickAccount"
          >
            <UserIcon />
            <ClientOnly>
              <SfBadge v-if="isLoggedIn" class="sf-badge--number logged-in-badge">
                <CheckIcon width="13" height="13" />
              </SfBadge>
            </ClientOnly>
          </SfLink>
          <SfButton
            class="sf-button--pure sf-header__action sf-header__action--wishlist"
            aria-label="Wishlist"
            data-testid="wishlistIcon"
            @click="clickWishlist"
          >
            <WishlistIcon />
            <SfBadge v-if="wishlistItemsQty" class="sf-badge--number cart-badge" :data-count="wishlistItemsQty">
              {{ wishlistItemsQty }}
            </SfBadge>
          </SfButton>
          <SfButton
            class="sf-button--pure sf-header__action"
            aria-label="Toggle cart sidebar"
            data-testid="minicartIcon"
            @click="clickCart"
          >
            <ShoppingBagIcon width="24" height="24" />
            <SfBadge v-if="cartTotalItems" class="sf-badge--number cart-badge">
              {{ cartTotalItems }}
            </SfBadge>
          </SfButton>
        </div>
      </template>
    </SfHeader>
  </div>
  <ClientOnly>
    <SearchBarResults
      v-if="isSearchOpen && !ab25019ShowV1"
      :visible="isSearchOpen"
      @SearchBar:hide="isSearchOpen = false"
    />
    <SfOverlay :visible="isSearchOpen && !ab25019ShowV1" />
  </ClientOnly>
</template>

<style lang="scss">
.ab25021V1 {
  .app-header {
    background: none;
  }
}
.app-header {
  position: relative;
  z-index: 2;
  background: var(--white-color);

  @include for-mobile {
    box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
    grid-gap: var(--spacer-xs);
    padding: 0 0 var(--spacer-xs);
  }
}

.header-on-top {
  z-index: 2;
}

.nav-item {
  --header-navigation-item-margin: 0 var(--spacer-sm);

  .sf-header-navigation-item__item--mobile {
    display: none;
  }
}

.logged-in-badge,
.cart-badge {
  position: absolute;
  bottom: 52%;
  left: 48%;
  --badge-width: 20px;
  --badge-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sf-header {
  --header-aside-margin: 0;
  --header-padding: var(--spacer-xs) var(--spacer-xs);
  --header-icons-margin: 0 var(--spacer-2xs) var(--spacer-xs);
  --header-width: 100%;
  --header-box-shadow: none;

  @include for-desktop {
    --header-padding: 0 var(--spacer-sm);
    --header-icons-margin: 0;
  }

  &__switchers {
    display: flex;
  }

  &__actions {
    flex: 0 0 100%;
    order: 2;

    @include for-desktop {
      flex: 1;
      order: -2;
      justify-content: flex-start;
    }
  }

  &__header {
    padding: 0;
  }

  .sf-header__mobile-menu-toggle {
    position: absolute;
    top: 17px;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: auto;
    height: calc(var(--spacer-lg) + var(--spacer-2xs));
    padding: 0 var(--spacer-xs);
    box-sizing: border-box;

    span {
      position: absolute;
      bottom: 4px;
      left: 0;
      right: 0;
      font-size: var(--spacer-xs);
      font-weight: var(--font-weight--normal);
      line-height: var(--spacer-sm);
      padding-top: 1px;
      padding-bottom: 1px;
      text-align: center;
    }

    @include for-desktop {
      display: none;
    }

    &.search {
      left: 42px;

      svg {
        margin-top: var(--spacer-2xs);
      }
    }
  }

  .sf-header__action {
    --header-action-margin: 0 0 0 var(--spacer-xs);
    --button-padding: var(--spacer-2xs);
    --button-size: 32px;

    @include for-mobile {
      --button-height: 32px;
    }

    @include for-desktop {
      --button-padding: 0;
      --header-action-margin: 0 0 0 40px;
    }

    &:hover,
    &:focus {
      --button-color: var(--primary-color);
      --c-link-hover: var(--primary-color);
    }
  }

  &__logo {
    order: 1;

    @include for-mobile {
      --header-logo-margin: 0;
      position: absolute;
      z-index: 1;
      left: 49%;
      top: 17.6px;
      transform: translate(-50%, 0);
    }
  }

  &__aside {
    order: 2;
    flex: 1;
    --header-aside-margin: 16px 0;

    @include for-desktop {
      --header-aside-margin: 0;
    }
  }

  &__icons {
    --header-icons-display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__navigation {
    display: none;
  }

  &__search {
    --button-height: 100%;
    --header-search-flex: 0 0 100%;
    --search-bar-width: 100%;
    margin: 0;

    @include for-desktop {
      --header-search-flex: 0 1 286px;
      --search-bar-width: 286px;
    }
  }

  .logged-in-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
      stroke-width: 2px;
    }
  }
}
</style>
